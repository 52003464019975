@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

@layer components {
    .primary-background {
        @apply bg-[#005CB4]
    }

    .accent-background {
        @apply bg-[#C9002B]
    }

    .primary-color {
        @apply text-[#005CB4]
    }

    .accent-color {
        @apply text-[#C9002B] 
    }

    .btn-primary {
        @apply ml-3 relative inline-flex items-center rounded-md disabled:bg-blue-300 px-4 py-2 border-transparent bg-primary-color text-sm font-medium text-white shadow-sm hover:bg-accent-color focus:outline-none focus:ring-2 focus:ring-accent-color focus:ring-offset-2 sm:w-auto
    }
}

.content-height {
    height: calc(100vh - 76px);
}

/* Top progress */
/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #C9002B;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #C9002B, 0 0 5px #C9002B;
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 20px;
    height: 20px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: #C9002B;
    border-left-color: #C9002B;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* End Top progress */